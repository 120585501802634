<template>
  
  <NuxtLink 
  v-if="props.type === 'link'"
  :to="props.to"
  :class="[ collapsed ? 'flex text-left lg:pl-[12px]' : 'flex lg:grid lg:text-left' ]"
  class="relative group cursor-pointer text-white rounded-md px-3.5 grid-cols-[auto_1fr_1.5rem_0.5rem_auto] py-2.5 focus:outline-none sm:px-2 sm:py-1.5 text-base/6 col-span-full items-center hover:bg-white/5"
  >
    <span v-if="props?.notifications > 0" class="absolute left-5 top-2 z-10 text-sm -translate-y-1/2 h-4 w-4 flex items-center justify-center bg-red-500 text-white text-xs/4 font-semibold rounded-full">{{ props.notifications }}</span>
    <Icon v-if="props?.icon" 
    :name="props.icon" 
    class="w-5 h-5 sm:w-5 sm:h-5" 
    :class="{ 'w-6 h-6': props?.iconSize === 'large' }"
    />
    <span class="ml-2.5" :class="[ collapsed ? 'lg:hidden' : '' ]">{{ props.label }}</span>
  </NuxtLink> 
  
  <button 
  v-else-if="props.type === 'button'"
  @click="props.function"
  :class="[ collapsed ? 'flex text-left lg:pl-[12px]' : 'flex lg:grid lg:text-left' ]"
  class="relative group cursor-pointer text-white rounded-md px-3.5 grid-cols-[auto_1fr_1.5rem_0.5rem_auto] py-2.5 focus:outline-none sm:px-2 sm:py-1.5 text-base/6 col-span-full items-center hover:bg-white/5">
    <Icon v-if="props?.icon" 
    :name="props.icon" 
    class="w-5 h-5 sm:w-5 sm:h-5" 
    :class="{ 'w-6 h-6': props?.iconSize === 'large' }"
    />
    <span class="ml-2.5" :class="[ collapsed ? 'lg:hidden' : '' ]">{{ props.label }}</span>
  </button> 

  <div 
  v-else-if="props.type === 'separator'" 
  class="col-span-full mx-3.5 my-1 h-px border-0 sm:mx-3 bg-white/10 forced-colors:bg-[CanvasText]" 
  role="separator"></div>

</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    required: true,
    default: 'link',
  },
  to: {
    type: String,
    required: false
  },
  label: {
    type: String,
    required: false
  },
  icon: {
    type: String,
    required: false
  },
  iconSize: {
    type: String,
    required: false
  },
  function: {
    type: Function,
    required: false
  },
  notifications: {
    type: Number,
    required: false,
    default: 0
  },
  collapsed: {
    type: Boolean,
    required: false,
    default: false
  }
})
</script>